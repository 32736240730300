// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyD-Wt1OHk-cz7T93KoNWP_lP5XYizt-W4Q",
    authDomain: "tezenisskiteam.firebaseapp.com",
    databaseURL: "https://tezenisskiteam.firebaseio.com",
    projectId: "tezenisskiteam",
    storageBucket: "tezenisskiteam.appspot.com",
    messagingSenderId: "981677801177",
    appId: "1:981677801177:web:c5f686d6b5ed50dfe648c8",
    //measurementId: "G-V9JB5LHN6Z"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
